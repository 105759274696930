@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?3491171');
  src:
    url('../font/fontello.eot?3491171#iefix') format('embedded-opentype'),
    url('../font/fontello.woff2?3491171') format('woff2'),
    url('../font/fontello.woff?3491171') format('woff'),
    url('../font/fontello.ttf?3491171') format('truetype'),
    url('../font/fontello.svg?3491171#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?3491171#fontello') format('svg');
  }
}
*/
[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-comment:before {
  content: '\e800';
} /* '' */
.icon-comment-2:before {
  content: '\e801';
} /* '' */
.icon-close:before {
  content: '\e802';
} /* '' */
.icon-gear:before {
  content: '\e803';
} /* '' */
.icon-list:before {
  content: '\e804';
} /* '' */
.icon-mark:before {
  content: '\e805';
} /* '' */
.icon-sort:before {
  content: '\e806';
} /* '' */
.icon-watch:before {
  content: '\e807';
} /* '' */
.icon-arrow:before {
  content: '\e808';
} /* '' */
.icon-arrow-right:before {
  content: '\e809';
} /* '' */
.icon-ready:before {
  content: '\e80a';
} /* '' */
.icon-times-circle:before {
  content: '\e80b';
} /* '' */
.icon-fcheck-circle:before {
  content: '\e80c';
} /* '' */
.icon-stage-11:before {
  content: '\e80d';
} /* '' */
.icon-stik:before {
  content: '\e810';
} /* '' */
.icon-file-download:before {
  content: '\e812';
} /* '' */
.icon-arrow-1:before {
  content: '\e813';
} /* '' */
.icon-pause:before {
  content: '\e814';
} /* '' */
.icon-i-calendar:before {
  content: '\e815';
} /* '' */
.icon-signout:before {
  content: '\e816';
} /* '' */
.icon-search:before {
  content: '\e817';
} /* '' */
.icon-all-stages:before {
  content: '\e818';
} /* '' */
.icon-stage-1:before {
  content: '\e819';
} /* '' */
.icon-stage-2:before {
  content: '\e81a';
} /* '' */
.icon-stage-3:before {
  content: '\e81b';
} /* '' */
.icon-stage-5:before {
  content: '\e81c';
} /* '' */
.icon-stage-6:before {
  content: '\e81d';
} /* '' */
.icon-stage-7:before {
  content: '\e81e';
} /* '' */
.icon-stage-8:before {
  content: '\e81f';
} /* '' */
.icon-stage-9:before {
  content: '\e820';
} /* '' */
.icon-stage-10:before {
  content: '\e821';
} /* '' */
.icon-drag:before {
  content: '\e822';
} /* '' */
.icon-stage-12:before {
  content: '\e823';
} /* '' */
.icon-stage-13:before {
  content: '\e824';
} /* '' */
.icon-stage-4:before {
  content: '\e825';
} /* '' */
.icon-lock:before {
  content: '\e828';
} /* '' */
.icon-eye:before {
  content: '\e829';
} /* '' */
.icon-user:before {
  content: '\e82a';
} /* '' */
